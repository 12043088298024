import { Route } from "@angular/router";

import { AuthGuard } from "@core/auth/guards/auth.guard";
import { NoAuthGuard } from "@core/auth/guards/no-auth.guard";

import { LayoutComponent } from "@layout/layout.component";

import { InitialDataResolver } from "./app.resolvers";

export const appRoutes: Route[] = [
    // Redirect empty path to 'dashboards/principal'
    {
        path: '',
        pathMatch : 'full',
        redirectTo: 'dashboards/main'
    },
    // Redirect signed-in user to the 'dashboards/principal'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch : 'full',
        redirectTo: 'dashboards/main'
    },

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [

            { path: 'sign-in', loadChildren: () => import('@modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('@modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('@modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboards',
                loadChildren: () => import('@modules/admin/dashboards/dashboards.module').then(m => m.DashboardsModule)
            },
            {
                path: 'masters',
                loadChildren: () => import('@modules/admin/masters/masters.module').then((routes) => routes.MastersModule),
            },
            {
                path: 'control',
                loadChildren: () => import('@modules/admin/control/control.module').then((routes) => routes.ControlModule),
            },
            // {
            // 	path: 'seguridad/accesos',
            // 	loadChildren: () => import('app/modules/admin/seguridad/acceso/acceso.seguridad.module').then(m => m.AccesoSeguridadModule),
            // }
        ]
    }
];