import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { CompanyState } from './company-state.type';

import { SiteStateService } from '../site/site-state.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyStateService
{
    // Definir el valor por defecto
    private defaultCompanyState: CompanyState = {
        companyId: 0,
        name: 'None Company',
        sites: [
            {
                siteId: 0,
                name: 'None Location'
            }
        ]
    };

    //private _companyState: ReplaySubject<CompanyState> = new ReplaySubject<CompanyState>(1);
    private _companySelected = new BehaviorSubject<CompanyState>(this.defaultCompanyState);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _siteStateService: SiteStateService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set companyState(value: CompanyState)
    {
        
        if(value.sites.length > 0)
        {
            this._siteStateService.siteStateList = value.sites;
            this._siteStateService.siteState = value.sites[0];
        }
        else
        {
            this._siteStateService.siteStateList = [];
            this._siteStateService.siteState = this.defaultCompanyState.sites[0];
        }

        // Store the value
        this._companySelected.next(value);
    }

    get companyState$(): Observable<CompanyState>
    {
        return this._companySelected.asObservable();
    }
}