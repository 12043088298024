import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { TekinHorizontalNavigationComponent } from '@tekin/components/navigation/horizontal/horizontal.component';
import { TekinNavigationService } from '@tekin/components/navigation/navigation.service';
import { TekinNavigationElement } from '@tekin/components/navigation/navigation.types';

@Component({
    selector       : 'tekin-horizontal-navigation-spacer-element',
    templateUrl    : './spacer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TekinHorizontalNavigationSpacerElementComponent implements OnInit, OnDestroy
{
    @Input() element: TekinNavigationElement | null = null; /* By JLD */
    @Input() name: string | null = null; /* By JLD */

    private _tekinHorizontalNavigationComponent: TekinHorizontalNavigationComponent | null = null; /* By JLD */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _tekinNavigationService: TekinNavigationService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the parent navigation component
        this._tekinHorizontalNavigationComponent = this._tekinNavigationService.getComponent(this.name ?? '');

        // Subscribe to onRefreshed on the navigation component
        this._tekinHorizontalNavigationComponent?.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
