import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';

import { TekinLoadingBarModule } from '@tekin/components/loading-bar';

import { SharedModule } from '@shared/shared.module';

import { EmptyLayoutComponent } from './empty.component';


@NgModule({
  declarations: [EmptyLayoutComponent],
  imports: [
    RouterModule,
    TekinLoadingBarModule,
    SharedModule
  ],
  exports     : [
      EmptyLayoutComponent
  ]
})
export class EmptyLayoutModule { }
