/* eslint-disable */
export const USER_DATA: {
    userId: string;
    userName: string;
    userWebId: string;
    userWebLogin: string;
    userWebName: string;
    email: string;
    avatar: string;
    status: string;
  } = {
    userId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
    userName: 'DBO',
    userWebId: 'dfbbd87e-05b8-2225-c6d8-e3d8e74gd7eg',
    userWebLogin: 'admin',
    userWebName: 'Daniel Caruajulca',
    email: 'daniel@indicium.pe',
    avatar: 'assets/images/avatars/male-18.jpg',
    status: 'online'
  };

  
  
  
  