import { NgModule } from '@angular/core';
import { TekinMediaWatcherService } from '@tekin/services/media-watcher/media-watcher.service';

@NgModule({
    providers: [
        TekinMediaWatcherService
    ]
})
export class TekinMediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _tekinMediaWatcherService: TekinMediaWatcherService)
    {
    }
}
