import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';

import { UserWebState } from '@core/states/user/user-web-state.type';
import { CompanyStateService } from '../company/company-state.service';

@Injectable({
    providedIn: 'root'
})
export class UserStateService
{
    private _userWebState: ReplaySubject<UserWebState> = new ReplaySubject<UserWebState>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _companyStateService: CompanyStateService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set userWebState(userWeb: UserWebState)
    {
        if(userWeb.user.companies.length > 0) {

            const firstCompany = userWeb.user.companies.find(c => c.companyId == userWeb.user.companyId);

            if(firstCompany)
                this._companyStateService.companyState = firstCompany;
        }

        // Store the value
        this._userWebState.next(userWeb);
    }

    get userWebState$(): Observable<UserWebState>
    {
        return this._userWebState.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<UserWebState>
    {
        return this._httpClient.get<UserWebState>('api/common/user').pipe(
            tap((user) => {
                this._userWebState.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: UserWebState): Observable<any>
    {
        return this._httpClient.patch<UserWebState>('api/common/user', {user}).pipe(
            map((response) => {
                this._userWebState.next(response);
            })
        );
    }
}
