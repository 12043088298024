<div class="tekin-horizontal-navigation-wrapper">

    <ng-container *ngFor="let element of navigation; trackBy: trackByFn">

        <!-- Skip the hidden items -->
        <ng-container *ngIf="(element.hidden && !element.hidden(element)) || !element.hidden">

            <!-- Basic -->
            <ng-container *ngIf="element.type === 'basic'">
                <tekin-horizontal-navigation-basic-element
                    class="tekin-horizontal-navigation-menu-item"
                    [element]="element"
                    [name]="name">
                </tekin-horizontal-navigation-basic-element>
            </ng-container>

            <!-- Branch: aside, collapsable, group -->
            <ng-container *ngIf="element.type === 'aside' || element.type === 'collapsable' || element.type === 'group'">
                <tekin-horizontal-navigation-branch-element
                    class="tekin-horizontal-navigation-menu-item"
                    [element]="element"
                    [name]="name">
                </tekin-horizontal-navigation-branch-element>
            </ng-container>

            <!-- Spacer -->
            <ng-container *ngIf="element.type === 'spacer'">
                <tekin-horizontal-navigation-spacer-element
                    class="tekin-horizontal-navigation-menu-item"
                    [element]="element"
                    [name]="name">
                </tekin-horizontal-navigation-spacer-element>
            </ng-container>

        </ng-container>

    </ng-container>

</div>
