import { NgModule } from '@angular/core';
import { TekinPlatformService } from '@tekin/services/platform/platform.service';

@NgModule({
    providers: [
        TekinPlatformService
    ]
})
export class TekinPlatformModule
{
    /**
     * Constructor
     */
    constructor(private _tekinPlatformService: TekinPlatformService)
    {
    }
}
