import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SiteState } from './site-state.type';

@Injectable({
    providedIn: 'root'
})
export class SiteStateService
{
    //private _siteState: ReplaySubject<SiteState> = new ReplaySubject<SiteState>(1);
    private _siteList = new BehaviorSubject<SiteState[]>([]);
    private _siteSelected = new BehaviorSubject<SiteState | null>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set siteState(value: SiteState)
    {
        // Store the value
        this._siteSelected.next(value);
    }

    get siteState$(): Observable<SiteState | null>
    {
        return this._siteSelected.asObservable();
    }

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set siteStateList(list: SiteState[])
    {
        // Store the value
        this._siteList.next(list);
    }

    get siteStateList$(): Observable<SiteState[]>
    {
        return this._siteList.asObservable();
    }
}