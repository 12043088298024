import { NgModule } from '@angular/core';
import { TekinScrollbarDirective } from '@tekin/directives/scrollbar/scrollbar.directive';

@NgModule({
    declarations: [
        TekinScrollbarDirective
    ],
    exports     : [
        TekinScrollbarDirective
    ]
})
export class TekinScrollbarModule
{
}
