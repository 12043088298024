import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { TekinLoadingService } from '@tekin/services/loading';

@Component({
    selector     : 'tekin-loading-bar',
    templateUrl  : './loading-bar.component.html',
    styleUrls    : ['./loading-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'tekinLoadingBar'
})
export class TekinLoadingBarComponent implements OnChanges, OnInit, OnDestroy
{
    @Input() autoMode: boolean = true;
    mode: 'determinate' | 'indeterminate' = 'determinate';
    progress: number = 0;
    show: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _tekinLoadingService: TekinLoadingService,
        private _cdRef: ChangeDetectorRef 
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Auto mode
        if ( 'autoMode' in changes )
        {
            const autoMode = changes['autoMode'].currentValue;

            // Set the auto mode in the service
            this._tekinLoadingService.setAutoMode(coerceBooleanProperty(autoMode));
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the service

        this._tekinLoadingService.mode$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                this.mode = value;
                this._cdRef.markForCheck();
            });

        this._tekinLoadingService.progress$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                this.progress = value;
                this._cdRef.markForCheck();
            });

        this._tekinLoadingService.show$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                setTimeout(() => {
                    this.show = value;
                    this._cdRef.detectChanges();
                });
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
