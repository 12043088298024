import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TekinDrawerComponent } from '@tekin/components/drawer/drawer.component';

@NgModule({
    declarations: [
        TekinDrawerComponent
    ],
    imports     : [
        CommonModule
    ],
    exports     : [
        TekinDrawerComponent
    ]
})
export class TekinDrawerModule
{
}
