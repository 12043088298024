/* tslint:disable:max-line-length */
import { TekinNavigationElement } from '@tekin/components/navigation';

export const defaultNavigation: TekinNavigationElement[] = [
    {
        id          : 'dashboards',
        title       : 'Dashboards',
        subtitle    : 'Diseño de indicadores',
        type        : 'group',
        icon        : '',
        children    : [
            {
                id   : 'dashboards.main',
                title: 'Main',
                type : 'basic',
                icon : 'heroicons_outline:chart-pie',
                link : '/dashboards/main'
            }
        ]
    },
    {
        id          : 'masters',
        title       : 'Masters',
        subtitle    : 'Data masters.',
        type        : 'group',
        icon        : '',
        children    : [
            {
                id   : 'maters.data',
                title: 'Data',
                type : 'collapsable',
                icon : 'heroicons_outline:pencil-alt',
                children : [
                    {
                        id   : 'masters.data.company',
                        title: 'Company',
                        type : 'basic',
                        icon : 'mat_outline:business',
                        link : '/masters/data/company'
                    },
                    {
                        id   : 'masters.data.areas',
                        title: 'Areas',
                        type : 'basic',
                        icon : 'mat_outline:margin',
                        link : '/masters/data/areas'
                    },
                    {
                        id   : 'masters.data.sites',
                        title: 'Sites',
                        type : 'basic',
                        icon : 'mat_outline:add_location',
                        link : '/masters/data/sites'
                    },
                    {
                        id   : 'masters.data.collaborators',
                        title: 'Collaborators',
                        type : 'basic',
                        icon : 'mat_outline:people',
                        link : '/masters/data/collaborators'
                    }
                ]
            }
        ]
    },
    {
        id          : 'control',
        title       : 'Control',
        subtitle    : 'Assist control.',
        type        : 'group',
        icon        : '',
        children    : [
            {
                id   : 'control.access',
                title: 'Access',
                type : 'collapsable',
                icon : 'mat_outline:portrait',
                children : [
                    {
                        id   : 'control.access.clocking',
                        title: 'Clocking',
                        type : 'basic',
                        icon : 'mat_outline:history_toggle_off',
                        link : '/control/access/clocking'
                    },
                    {
                        id   : 'control.access.assists',
                        title: 'Assists',
                        type : 'basic',
                        icon : 'mat_outline:edit_note',
                        link : '/control/access/assists'
                    }
                ]
            },
            {
                id   : 'control.report',
                title: 'Reports',
                type : 'collapsable',
                icon : 'mat_outline:apps',
                children : [
                    {
                        id   : 'control.report.monthly',
                        title: 'Monthly',
                        type : 'basic',
                        icon : 'mat_outline:article',
                        link : '/control/reports/monthly'
                    },
                    {
                        id   : 'control.report.weekly',
                        title: 'Weekly',
                        type : 'basic',
                        icon : 'mat_outline:article',
                        link : '/control/reports/weekly'
                    },
                    {
                        id   : 'control.report.daily',
                        title: 'Daily',
                        type : 'basic',
                        icon : 'mat_outline:article',
                        link : '/control/reports/daily'
                    },
                    // {
                    //     id   : 'control.report.visitors',
                    //     title: 'Visitors',
                    //     type : 'basic',
                    //     icon : 'mat_outline:article',
                    //     link : '/control/reports/visitors'
                    // },
                    // {
                    //     id   : 'control.report.absence',
                    //     title: 'Absences',
                    //     type : 'basic',
                    //     icon : 'mat_outline:article',
                    //     link : '/control/report/absence'
                    // }
                ]
            }
        ]
    }
];

export const compactNavigation: TekinNavigationElement[] = [
    // {
    //     id   : 'example',
    //     title: 'Compact',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // }
];

export const futuristicNavigation: TekinNavigationElement[] = [
    // {
    //     id   : 'example',
    //     title: 'Futuristic',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // }
];

export const horizontalNavigation: TekinNavigationElement[] = [
//     {
//         id   : 'example',
//         title: 'Horizontal',
//         type : 'basic',
//         icon : 'heroicons_outline:chart-pie',
//         link : '/example'
//     }
];
