import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';


// -----------------------------------------------------------------------------------------------------
// @ Public methods
// -----------------------------------------------------------------------------------------------------

/**
 * Can match
 *
 * @param route
 * @param segments
 */
export const AuthGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  return _funCheck(segments);

};


// -----------------------------------------------------------------------------------------------------
// @ Private methods
// -----------------------------------------------------------------------------------------------------

/**
 * Check the authenticated status
 *
 * @param segments
 * @private
 */
const _funCheck = (segments: UrlSegment[]): Observable<boolean | UrlTree> => {
  const _authService = inject(AuthService); 
  const _router = inject(Router); 

  // Check the authentication status
  return _authService.check().pipe(switchMap((authenticated) => {

    // If the user is not authenticated...
    if (!authenticated) {
      // Redirect to the sign-in page with a redirectUrl param
      const redirectURL = `/${segments.join('/')}`;

      const fullPathRedirect = `sign-in?redirectURL=${redirectURL}`;

      const urlTree = _router.parseUrl(fullPathRedirect); // Asegúrate de que _router esté disponible aquí

      return of(urlTree); 
    }

    // Allow the access
    return of(true);

  }));
};