<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="sites">
    <ng-container *ngTemplateOutlet="iconSite"></ng-container>
</button>

<!-- Language menu -->
<mat-menu
    [xPosition]="'before'"
    #sites="matMenu">
    <ng-container *ngFor="let site of availableSites">
        <button
            mat-menu-item
            (click)="setActiveSite(site)">
            <span class="flex items-center">
                <ng-container *ngTemplateOutlet="iconSite"></ng-container>
                <span class="ml-3">{{site.name}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

<!-- Flag image template -->
<ng-template
    let-lang
    #iconSite>
    <span class="relative w-6 overflow-hidden">
        <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-0"></span>
        <img
            class="w-full"
            [src]="'assets/icons/location.png'"
            [alt]="'Select Location'">
    </span>
</ng-template>
