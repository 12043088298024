
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { SiteState } from '@core/states/site/site-state.type';
import { UserWebState } from '@core/states/user/user-web-state.type';
//import { UserStateService } from '@core/states/user/user-state.service';
import { SiteStateService } from '@core/states/site/site-state.service';

@Component({
    selector       : 'sites',
    templateUrl    : './sites.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'sites'
})
export class SitesComponent implements OnInit, OnDestroy
{
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    
    availableSites!: SiteState[];

    /**
     * Constructor
     */
    constructor(
        private _cdRef: ChangeDetectorRef,
        private _siteStateService: SiteStateService
    )
    {
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
    // Subscribe to user changes
    this._siteStateService.siteStateList$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((siteList: SiteState[]) => {

                this.availableSites = siteList;

                // Mark for check
                this._cdRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    setActiveSite(site: SiteState): void
    {
        this._siteStateService.siteState = site;
    }

}
