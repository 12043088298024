import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { getStorage, provideStorage } from '@angular/fire/storage';

// import { environment } from '@environments/environment';
//--------------------------------------------------------------------------

import { TekinModule } from '@tekin/tekin.module';
import { TekinConfigModule } from '@tekin/services/config';
import { TekinMockApiModule } from '@tekin/lib/mock-api';

import { CoreModule } from '@core/core.module';
import { appConfig } from '@core/config/app.config';
import { MOCK_API_SERVICES } from '@mock_api/index';

import { LayoutModule } from './layout/layout.module';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';

import { SnackbarNotificationModule } from '@tekin/components/notification';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';

import { es } from 'date-fns/locale';

// import localeEsPE from '@angular/common/locales/es-PE';
// registerLocaleData(localeEsPE);


const routerConfig: ExtraOptions = {
  preloadingStrategy       : PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

// const MY_DATE_FORMATS = {
//   parse: {
//     dateInput: 'DD/MM/YYYY',
//   },
//   display: {
//     dateInput: 'DD/MM/YYYY',
//     monthYearLabel: 'MMMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY'
//   }
// };

export const MY_DATE_FORMATS = {
  parse: {
      dateInput: 'YYYY-MM-DD HH:mm:ss',
  },
  display: {
      dateInput: 'YYYY-MM-DD HH:mm:ss',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    BrowserAnimationsModule,

    SnackbarNotificationModule,

    // TekinModule, TekinConfigModule & TekinMockApiModule
    TekinModule,
    TekinConfigModule.forRoot(appConfig),
    TekinMockApiModule.forRoot(MOCK_API_SERVICES),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    MatNativeDateModule,
    // provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // provideStorage(() => getStorage())
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: es},
    {provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
