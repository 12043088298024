<!-- element wrapper -->
<div
    class="tekin-vertical-navigation-element-wrapper"
    [class.tekin-vertical-navigation-element-has-subtitle]="!!element.subtitle"
    [ngClass]="element.classes?.wrapper">

    <div class="tekin-vertical-navigation-element">

        <!-- Icon -->
        <ng-container *ngIf="element.icon">
            <mat-icon
                class="tekin-vertical-navigation-element-icon"
                [ngClass]="element.classes?.icon"
                [svgIcon]="element.icon"></mat-icon>
        </ng-container>

        <!-- Title & Subtitle -->
        <div class="tekin-vertical-navigation-element-title-wrapper">
            <div class="tekin-vertical-navigation-element-title">
                <span [ngClass]="element.classes?.title">
                    {{element.title}}
                </span>
            </div>
            <ng-container *ngIf="element.subtitle">
                <div class="tekin-vertical-navigation-element-subtitle">
                    <span [ngClass]="element.classes?.subtitle">
                        {{element.subtitle}}
                    </span>
                </div>
            </ng-container>
        </div>

        <!-- Badge -->
        <ng-container *ngIf="element.badge">
            <div class="tekin-vertical-navigation-element-badge">
                <div
                    class="tekin-vertical-navigation-element-badge-content"
                    [ngClass]="element.badge.classes">
                    {{element.badge.title}}
                </div>
            </div>
        </ng-container>

    </div>

</div>

<ng-container *ngFor="let element of element.children; trackBy: trackByFn">

    <!-- Skip the hidden elements -->
    <ng-container *ngIf="(element.hidden && !element.hidden(element)) || !element.hidden">

        <!-- Basic -->
        <ng-container *ngIf="element.type === 'basic'">
            <tekin-vertical-navigation-basic-element
                [element]="element"
                [name]="name"></tekin-vertical-navigation-basic-element>
        </ng-container>

        <!-- Collapsable -->
        <ng-container *ngIf="element.type === 'collapsable'">
            <tekin-vertical-navigation-collapsable-element
                [element]="element"
                [name]="name"
                [autoCollapse]="autoCollapse"></tekin-vertical-navigation-collapsable-element>
        </ng-container>

        <!-- Divider -->
        <ng-container *ngIf="element.type === 'divider'">
            <tekin-vertical-navigation-divider-element
                [element]="element"
                [name]="name"></tekin-vertical-navigation-divider-element>
        </ng-container>

        <!-- Group -->
        <ng-container *ngIf="element.type === 'group'">
            <tekin-vertical-navigation-group-element
                [element]="element"
                [name]="name"></tekin-vertical-navigation-group-element>
        </ng-container>

        <!-- Spacer -->
        <ng-container *ngIf="element.type === 'spacer'">
            <tekin-vertical-navigation-spacer-element
                [element]="element"
                [name]="name"></tekin-vertical-navigation-spacer-element>
        </ng-container>

    </ng-container>

</ng-container>
