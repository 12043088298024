import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from '@core/auth/auth.utils';
import { UserStateService } from '@core/states/user/user-state.service';
import { environment } from '@environments/environment';
import { HttpUtilsService } from '@core/services/common/http-utils.service';
import { UserWebState } from '@core/states/user/user-web-state.type';

@Injectable()
export class AuthService
{
    private readonly apiURL: string = environment.baseURL + 'api/security/access/auth';
    //private readonly apiURL: string = 'api/security/auth';
    private headers: HttpHeaders;

    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userStateService: UserStateService,
        private _httpUtils: HttpUtilsService
    )
    {
        this.headers = this._httpUtils.getHttpHeaders();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post(`${ this.apiURL }/forgot-password`, email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post(`${ this.apiURL }/reset-password`, password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { userLogin: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${ this.apiURL }/sign-in`, credentials).pipe(
            switchMap((response: any) => {

                if (response?.isSuccess && response?.data)
                {
                    const userWeb: UserWebState = response.data.dataUserWeb;
                    // Store the access token in the local storage
                    this.accessToken = response.data.dataTokenWeb.accessToken;

                    // Store the user on the user service
                    this._userStateService.userWebState = userWeb;
                }

                // Set the authenticated flag to true
                this._authenticated = response?.isSuccess;

                // Return a new observable with the response
                return of(response?.isSuccess);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.get(`${ this.apiURL }/user`, 
        //{ accessToken: this.accessToken }, //body
        { headers: this.headers},
        ).pipe(
            catchError((err) => {
                // Return false
                return of(false);
            }),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response?.isSuccess && response?.data)
                {
                    // Store the user on the user service
                    this._userStateService.userWebState = response.data;

                    // Store the access token in the local storage
                    // this.accessToken = response.data.dataTokenWeb.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = response?.isSuccess;
                
                // Return
                return of(response?.isSuccess);

            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post(`${ this.apiURL }/sign-up`, user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post(`${ this.apiURL }/unlock-session`, credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
