import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpUtilsService {



  constructor() { }


  /**
	 * get standard content-type
	 */
  getHttpHeaders(): HttpHeaders {
    let reqheaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + localStorage.getItem('accessToken')
    });

		return reqheaders;
	}

  getBrowserName(): string 
  {
    const userAgent = navigator.userAgent;
    let browserName;

    // Identificar el nombre del navegador
    if (userAgent.includes("Firefox")) {
      browserName = "Firefox";
    } else if (userAgent.includes("Chrome")) {
      browserName = "Chrome";
    } else if (userAgent.includes("Safari")) {
      browserName = "Safari";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      browserName = "Opera";
    } else if (userAgent.includes("Edge")) {
      browserName = "Edge";
    } else if (userAgent.includes("MSIE")) {
      browserName = "Internet Explorer";
    } else {
      browserName = "Unknown";
    }

    return browserName;
  }
}
