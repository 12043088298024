<!-- element wrapper -->
<div
    class="tekin-vertical-navigation-element-wrapper"
    [class.tekin-vertical-navigation-element-has-subtitle]="!!element.subtitle"
    [ngClass]="element.classes?.wrapper">

    <!-- element with an internal link -->
    <ng-container *ngIf="element.link && !element.externalLink && !element.function && !element.disabled">
        <a
            class="tekin-vertical-navigation-element"
            [ngClass]="{'tekin-vertical-navigation-element-active-forced': element.active}"
            [routerLink]="[element.link]"
            [fragment]="element.fragment"
            [preserveFragment]="element.preserveFragment ?? false"
            [queryParams]="element.queryParams ?? null"
            [queryParamsHandling]="element.queryParamsHandling ?? null"
            [routerLinkActive]="'tekin-vertical-navigation-element-active'"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="element.tooltip || ''">
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- element with an external link -->
    <ng-container *ngIf="element.link && element.externalLink && !element.function && !element.disabled">
        <a
            class="tekin-vertical-navigation-element"
            [href]="element.link"
            [target]="element.target || '_self'"
            [matTooltip]="element.tooltip || ''">
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- element with a function -->
    <ng-container *ngIf="!element.link && element.function && !element.disabled">
        <div
            class="tekin-vertical-navigation-element"
            [ngClass]="{'tekin-vertical-navigation-element-active-forced': element.active}"
            [matTooltip]="element.tooltip || ''"
            (click)="element.function(element)">
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- element with an internal link and function -->
    <ng-container *ngIf="element.link && !element.externalLink && element.function && !element.disabled">
        <a
            class="tekin-vertical-navigation-element"
            [ngClass]="{'tekin-vertical-navigation-element-active-forced': element.active}"
            [routerLink]="[element.link]"
            [fragment]="element.fragment"
            [preserveFragment]="element.preserveFragment ?? false"
            [queryParams]="element.queryParams ?? null"
            [queryParamsHandling]="element.queryParamsHandling ?? null"
            [routerLinkActive]="'tekin-vertical-navigation-element-active'"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="element.tooltip || ''"
            (click)="element.function(element)">
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- element with an external link and function -->
    <ng-container *ngIf="element.link && element.externalLink && element.function && !element.disabled">
        <a
            class="tekin-vertical-navigation-element"
            [href]="element.link"
            [target]="element.target || '_self'"
            [matTooltip]="element.tooltip || ''"
            (click)="element.function(element)">
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- element with a no link and no function -->
    <ng-container *ngIf="!element.link && !element.function && !element.disabled">
        <div
            class="tekin-vertical-navigation-element"
            [ngClass]="{'tekin-vertical-navigation-element-active-forced': element.active}"
            [matTooltip]="element.tooltip || ''">
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- element is disabled -->
    <ng-container *ngIf="element.disabled">
        <div
            class="tekin-vertical-navigation-element tekin-vertical-navigation-element-disabled"
            [matTooltip]="element.tooltip || ''">
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </div>
    </ng-container>

</div>

<!-- element template -->
<ng-template #elementTemplate>

    <!-- Icon -->
    <ng-container *ngIf="element.icon">
        <mat-icon
            class="tekin-vertical-navigation-element-icon"
            [ngClass]="element.classes?.icon"
            [svgIcon]="element.icon"></mat-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="tekin-vertical-navigation-element-title-wrapper">
        <div class="tekin-vertical-navigation-element-title">
            <span [ngClass]="element.classes?.title">
                {{element.title}}
            </span>
        </div>
        <ng-container *ngIf="element.subtitle">
            <div class="tekin-vertical-navigation-element-subtitle">
                <span [ngClass]="element.classes?.subtitle">
                    {{element.subtitle}}
                </span>
            </div>
        </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="element.badge">
        <div class="tekin-vertical-navigation-element-badge">
            <div
                class="tekin-vertical-navigation-element-badge-content"
                [ngClass]="element.badge.classes">
                {{element.badge.title}}
            </div>
        </div>
    </ng-container>

</ng-template>
