<div class="tekin-vertical-navigation-wrapper">

    <!-- Header -->
    <div class="tekin-vertical-navigation-header">
        <ng-content select="[tekinVerticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div
        class="tekin-vertical-navigation-content"
        tekinScrollbar
        [tekinScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}"
        #navigationContent>

        <!-- Content header -->
        <div class="tekin-vertical-navigation-content-header">
            <ng-content select="[tekinVerticalNavigationContentHeader]"></ng-content>
        </div>

        <!-- Elements -->
        <ng-container *ngFor="let element of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(element.hidden && !element.hidden(element)) || !element.hidden">

                <!-- Aside -->
                <ng-container *ngIf="element.type === 'aside'">
                    <tekin-vertical-navigation-aside-element
                        [element]="element"
                        [name]="name"
                        [activeElementId]="activeAsideElementId!"
                        [autoCollapse]="autoCollapse"
                        [skipChildren]="true"
                        (click)="toggleAside(element)">
                    </tekin-vertical-navigation-aside-element>
                </ng-container>

                <!-- Basic -->
                <ng-container *ngIf="element.type === 'basic'">
                    <tekin-vertical-navigation-basic-element
                        [element]="element"
                        [name]="name"></tekin-vertical-navigation-basic-element>
                </ng-container>

                <!-- Collapsable -->
                <ng-container *ngIf="element.type === 'collapsable'">
                    <tekin-vertical-navigation-collapsable-element
                        [element]="element"
                        [name]="name"
                        [autoCollapse]="autoCollapse"></tekin-vertical-navigation-collapsable-element>
                </ng-container>

                <!-- Divider -->
                <ng-container *ngIf="element.type === 'divider'">
                    <tekin-vertical-navigation-divider-element
                        [element]="element"
                        [name]="name"></tekin-vertical-navigation-divider-element>
                </ng-container>

                <!-- Group -->
                <ng-container *ngIf="element.type === 'group'">
                    <tekin-vertical-navigation-group-element
                        [element]="element"
                        [name]="name"
                        [autoCollapse]="autoCollapse"></tekin-vertical-navigation-group-element>
                </ng-container>

                <!-- Spacer -->
                <ng-container *ngIf="element.type === 'spacer'">
                    <tekin-vertical-navigation-spacer-element
                        [element]="element"
                        [name]="name"></tekin-vertical-navigation-spacer-element>
                </ng-container>

            </ng-container>

        </ng-container>

        <!-- Content footer -->
        <div class="tekin-vertical-navigation-content-footer">
            <ng-content select="[tekinVerticalNavigationContentFooter]"></ng-content>
        </div>

    </div>

    <!-- Footer -->
    <div class="tekin-vertical-navigation-footer">
        <ng-content select="[tekinVerticalNavigationFooter]"></ng-content>
    </div>

</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideElementId">
    <div
        class="tekin-vertical-navigation-aside-wrapper"
        tekinScrollbar
        [tekinScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}"
        [@fadeInLeft]="position === 'left'"
        [@fadeInRight]="position === 'right'"
        [@fadeOutLeft]="position === 'left'"
        [@fadeOutRight]="position === 'right'">

        <!-- Items -->
        <ng-container *ngFor="let element of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(element.hidden && !element.hidden(element)) || !element.hidden">

                <!-- Aside -->
                <ng-container *ngIf="element.type === 'aside' && element.id === activeAsideElementId">
                    <tekin-vertical-navigation-aside-element
                        [element]="element"
                        [name]="name"
                        [autoCollapse]="autoCollapse"></tekin-vertical-navigation-aside-element>
                </ng-container>

            </ng-container>

        </ng-container>

    </div>
</ng-container>
