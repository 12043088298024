export const environment = {
	production: false,
    baseURL: 'https://wapp-tidvakt-demo-back-web-api.azurewebsites.net/',
    AwsS3BucketConfig: {
        region: 'us-east-2',
        bucketName: 'bucket-angelus-client-bpm-assets',
        // accessKeyId: 'AKIATEL53D3YKYFHKHWN',
        // secretAccessKey: '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',
        accessKeyId: 'AKIATEL53D3YOO3UIT5M',
        secretAccessKey: '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',
        tidvaktImgPhotosPath: 'dev/tidvakt/images/photos'
    }
}
