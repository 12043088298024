import { NgModule } from '@angular/core';
import { TekinSplashScreenService } from '@tekin/services/splash-screen/splash-screen.service';

@NgModule({
    providers: [
        TekinSplashScreenService
    ]
})
export class TekinSplashScreenModule
{
    /**
     * Constructor
     */
    constructor(private _tekinSplashScreenService: TekinSplashScreenService)
    {
    }
}
