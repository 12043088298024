<ng-container *ngIf="!child">
    <div
        [ngClass]="{'tekin-horizontal-navigation-menu-active': trigger.menuOpen,
                 'tekin-horizontal-navigation-menu-active-forced': element.active}"
        [matMenuTriggerFor]="matMenu"
        (onMenuOpen)="triggerChangeDetection()"
        (onMenuClose)="triggerChangeDetection()"
        #trigger="matMenuTrigger">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: element}"></ng-container>
    </div>
</ng-container>

<mat-menu
    class="tekin-horizontal-navigation-menu-panel"
    [overlapTrigger]="false"
    #matMenu="matMenu">

    <ng-container *ngFor="let element of element.children; trackBy: trackByFn">

        <!-- Skip the hidden items -->
        <ng-container *ngIf="(element.hidden && !element.hidden(element)) || !element.hidden">

            <!-- Basic -->
            <ng-container *ngIf="element.type === 'basic'">
                <div
                    class="tekin-horizontal-navigation-menu-element"
                    [disabled]="element.disabled"
                    mat-menu-item>
                    <tekin-horizontal-navigation-basic-element
                        [element]="element"
                        [name]="name"></tekin-horizontal-navigation-basic-element>
                </div>
            </ng-container>

            <!-- Branch: aside, collapsable, group -->
            <ng-container *ngIf="element.type === 'aside' || element.type === 'collapsable' || element.type === 'group'">
                <div
                    class="tekin-horizontal-navigation-menu-element"
                    [disabled]="element.disabled"
                    [matMenuTriggerFor]="branch.matMenu"
                    mat-menu-item>
                    <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: element}"></ng-container>
                    <tekin-horizontal-navigation-branch-element
                        [child]="true"
                        [element]="element"
                        [name]="name"
                        #branch></tekin-horizontal-navigation-branch-element>
                </div>
            </ng-container>

            <!-- Divider -->
            <ng-container *ngIf="element.type === 'divider'">
                <div class="tekin-horizontal-navigation-menu-element" mat-menu-item>
                    <tekin-horizontal-navigation-divider-element
                        [element]="element"
                        [name]="name">
                    </tekin-horizontal-navigation-divider-element>
                </div>
            </ng-container>

        </ng-container>

    </ng-container>

</mat-menu>

<!-- Item template -->
<ng-template
    let-item
    #itemTemplate>

    <div
        class="tekin-horizontal-navigation-element-wrapper"
        [class.tekin-horizontal-navigation-element-has-subtitle]="!!item.subtitle"
        [ngClass]="item.classes?.wrapper">

        <div
            class="tekin-horizontal-navigation-element"
            [ngClass]="{'tekin-horizontal-navigation-element-disabled': item.disabled,
                         'tekin-horizontal-navigation-element-active-forced': item.active}"
            [matTooltip]="item.tooltip || ''">

            <!-- Icon -->
            <ng-container *ngIf="item.icon">
                <mat-icon
                    class="tekin-horizontal-navigation-element-icon"
                    [ngClass]="item.classes?.icon"
                    [svgIcon]="item.icon"></mat-icon>
            </ng-container>

            <!-- Title & Subtitle -->
            <div class="tekin-horizontal-navigation-element-title-wrapper">
                <div class="tekin-horizontal-navigation-element-title">
                    <span [ngClass]="item.classes?.title">
                        {{item.title}}
                    </span>
                </div>
                <ng-container *ngIf="item.subtitle">
                    <div class="tekin-horizontal-navigation-element-subtitle text-hint">
                        <span [ngClass]="item.classes?.subtitle">
                            {{item.subtitle}}
                        </span>
                    </div>
                </ng-container>
            </div>

            <!-- Badge -->
            <ng-container *ngIf="item.badge">
                <div class="tekin-horizontal-navigation-element-badge">
                    <div
                        class="tekin-horizontal-navigation-element-badge-content"
                        [ngClass]="item.badge.classes">
                        {{item.badge.title}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</ng-template>
