import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';

// -----------------------------------------------------------------------------------------------------
// @ Public functions
// -----------------------------------------------------------------------------------------------------

/**
 * Can match
 *
 * @param route
 * @param segments
 */

export const NoAuthGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  return _funCheck();

};

// -----------------------------------------------------------------------------------------------------
// @ Private methods
// -----------------------------------------------------------------------------------------------------
/**
 * Check the authenticated status
 *
 * @private
 */
const _funCheck = (): Observable<boolean> => {
  const _authService = inject(AuthService); 
  
    // Check the authentication status and return an observable of
    // "true" or "false" to allow or prevent the access
    return _authService.check().pipe(
      switchMap((authenticated) => of(!authenticated))
  );
};