import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TekinScrollbarModule } from '@tekin/directives/scrollbar/public-api';

import { TekinHorizontalNavigationBasicElementComponent } from '@tekin/components/navigation/horizontal/elements/basic/basic.component';
import { TekinHorizontalNavigationBranchElementComponent } from '@tekin/components/navigation/horizontal/elements/branch/branch.component';
import { TekinHorizontalNavigationDividerElementComponent } from '@tekin/components/navigation/horizontal/elements/divider/divider.component';
import { TekinHorizontalNavigationSpacerElementComponent } from '@tekin/components/navigation/horizontal/elements/spacer/spacer.component';
import { TekinHorizontalNavigationComponent } from '@tekin/components/navigation/horizontal/horizontal.component';

import { TekinVerticalNavigationAsideElementComponent } from '@tekin/components/navigation/vertical/elements/aside/aside.component';
import { TekinVerticalNavigationBasicElementComponent } from '@tekin/components/navigation/vertical/elements/basic/basic.component';
import { TekinVerticalNavigationCollapsableElementComponent } from '@tekin/components/navigation/vertical/elements/collapsable/collapsable.component';
import { TekinVerticalNavigationDividerElementComponent } from '@tekin/components/navigation/vertical/elements/divider/divider.component';
import { TekinVerticalNavigationGroupElementComponent } from '@tekin/components/navigation/vertical/elements/group/group.component';
import { TekinVerticalNavigationSpacerElementComponent } from '@tekin/components/navigation/vertical/elements/spacer/spacer.component';
import { TekinVerticalNavigationComponent } from '@tekin/components/navigation/vertical/vertical.component';

@NgModule({
    declarations: [
        TekinHorizontalNavigationBasicElementComponent,
        TekinHorizontalNavigationBranchElementComponent,
        TekinHorizontalNavigationDividerElementComponent,
        TekinHorizontalNavigationSpacerElementComponent,
        TekinHorizontalNavigationComponent,

        TekinVerticalNavigationAsideElementComponent,
        TekinVerticalNavigationBasicElementComponent,
        TekinVerticalNavigationCollapsableElementComponent,
        TekinVerticalNavigationDividerElementComponent,
        TekinVerticalNavigationGroupElementComponent,
        TekinVerticalNavigationSpacerElementComponent,
        TekinVerticalNavigationComponent
    ],
    imports     : [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        TekinScrollbarModule
    ],
    exports     : [
        TekinHorizontalNavigationComponent,
        TekinVerticalNavigationComponent
    ]
})
export class TekinNavigationModule
{
}
