import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { TekinNavigationElement } from '@tekin/components/navigation';
import { TekinMockApiService } from '@tekin/lib/mock-api';

import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from './data';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi
{
    private readonly _compactNavigation: TekinNavigationElement[] = compactNavigation;
    private readonly _defaultNavigation: TekinNavigationElement[] = defaultNavigation;
    private readonly _futuristicNavigation: TekinNavigationElement[] = futuristicNavigation;
    private readonly _horizontalNavigation: TekinNavigationElement[] = horizontalNavigation;

    /**
     * Constructor
     */
    constructor(private _tekinMockApiService: TekinMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    private registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._tekinMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavElement) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( compactNavElement.id === compactNavElement.id )
                        {
                            compactNavElement.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavElement) => {
                    this._defaultNavigation.forEach((defaultNavElement) => {
                        if ( defaultNavElement.id === futuristicNavElement.id )
                        {
                            futuristicNavElement.children = cloneDeep(defaultNavElement.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavElement) => {
                    this._defaultNavigation.forEach((defaultNavElement) => {
                        if ( defaultNavElement.id === horizontalNavElement.id )
                        {
                            horizontalNavElement.children = cloneDeep(defaultNavElement.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        default   : cloneDeep(this._defaultNavigation), //0
                        compact   : cloneDeep(this._compactNavigation), //1
                        futuristic: cloneDeep(this._futuristicNavigation), //2
                        horizontal: cloneDeep(this._horizontalNavigation) //3
                    }
                ];
            });
    }
}
