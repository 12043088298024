
import { NavigationMockApi } from "@mock_api/common/navigation/api";

import { AuthMockApi } from '@mock_api/common/auth/api';
import { UserMockApi } from '@mock_api/common/user/api';
import { IconsMockApi } from "@mock_api/ui/icons/api";

export const MOCK_API_SERVICES = [

    AuthMockApi,
    IconsMockApi,
    NavigationMockApi,
    UserMockApi

];