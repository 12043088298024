import {
    provideTransloco,
    TranslocoModule
} from '@ngneat/transloco';
import { Injectable, isDevMode, NgModule } from '@angular/core';

import { TranslocoHttpLoader } from './transloco-http-loader';

@NgModule({
    exports: [ TranslocoModule ],
    providers: [
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id   : 'en',
                        label: 'English'
                    },
                    {
                        id   : 'es',
                        label: 'Spanish'
                    }
                ],
                defaultLang     : 'en',
                fallbackLang    : 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader
        }),
    ],
})
export class TranslocoCoreModule
{
}
