
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TekinLoadingInterceptor } from '@tekin/services/loading/loading.interceptor';

@NgModule({
    providers: [
        {
            provide : HTTP_INTERCEPTORS,
            useClass: TekinLoadingInterceptor,
            multi   : true
        }
    ]
})
export class TekinLoadingModule
{
}
