import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { TekinConfirmationModule } from '@tekin/services/confirmation';
import { TekinLoadingModule } from '@tekin/services/loading';
import { TekinMediaWatcherModule } from '@tekin/services/media-watcher/media-watcher.module';
import { TekinPlatformModule } from '@tekin/services/platform/platform.module';
import { TekinSplashScreenModule } from '@tekin/services/splash-screen/splash-screen.module';
import { TekinUtilsModule } from '@tekin/services/utils/utils.module';

@NgModule({
    imports  : [
        TekinConfirmationModule,
        TekinLoadingModule,
        TekinMediaWatcherModule,
        TekinPlatformModule,
        TekinSplashScreenModule,
        TekinUtilsModule
    ],
    providers: [
        {
            // Disable 'theme' sanity check
            provide : MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme  : false,
                version: true
            }
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        }
    ]
})
export class TekinModule
{
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: TekinModule)
    {
        if ( parentModule )
        {
            throw new Error('TekinModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
